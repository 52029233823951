import { NavigationHookAfter, RouteLocationNormalized } from 'vue-router';
import { AppUserType } from '~/enums/permissions/_types';
import { useAuthStore } from '~/plugins/auth';
import { useAnnouncementStore } from '~/stores/announcement';
import { useAppFolderDetailStore } from '~/stores/appFolderDetail';
import { useAppUserDetailStore } from '~/stores/appUserDetail';
import { useEmailTemplateDetailStore } from '~/stores/emailTemplateDetail';
import { useInstitutionDetailStore } from '~/stores/institutionDetail';
import { useOrderDetailStore } from '~/stores/orderDetail';
import { useProgramDetailStore } from '~/stores/programDetail';
import { useStudentDetailStore } from '~/stores/studentDetail';

const after: NavigationHookAfter = (to) => {
    resetInstitutionDetailStore(to);
    resetAppFolderDetailStore(to);
    resetAppUserDetailStore(to);
    resetAnnouncementStore(to);
    resetEmailTemplateDetailStore(to);
    resetProgramDetailStore(to);
    resetOrderDetailStore(to);
    resetStudentDetailStore(to);
};

const resetAnnouncementStore = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('admin-announcements-id')) {
        useAnnouncementStore().reset();
    }
};

const resetInstitutionDetailStore = (to: RouteLocationNormalized) => {
    const { user } = useAuthStore();
    if (!to.name?.toString().startsWith(user?.Type === AppUserType.Client ? 'institutions' : 'institutions-id')) {
        useInstitutionDetailStore().reset();
    }
};

const resetAppFolderDetailStore = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('admin-files')) {
        useAppFolderDetailStore().reset();
    }
};

const resetAppUserDetailStore = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('admin-users-id')) {
        useAppUserDetailStore().reset();
    }
};

const resetEmailTemplateDetailStore = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('admin-email-templates-id')) {
        useEmailTemplateDetailStore().reset();
    }
};

const resetProgramDetailStore = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('programs-id')) {
        useProgramDetailStore().reset();
    }
};

const resetOrderDetailStore = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('orders-id')) {
        useOrderDetailStore().reset();
    }
};

const resetStudentDetailStore = (to: RouteLocationNormalized) => {
    if (!to.name?.toString().startsWith('certificates-students-id')) {
        useStudentDetailStore().reset();
    }
};

export default after;
