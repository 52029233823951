<script lang="ts" setup>
import { ProgramRevisionValidityFlags } from '~/api/programs/_types';

const props = defineProps<{
    flags: ProgramRevisionValidityFlags;
    multiple?: boolean;
}>();

const labelSuffix = computed(() => (props.multiple ? '' : '_single'));
</script>

<template>
    <q-icon
        v-if="flags?.LangsMisconfigured || flags?.NoValidInstitution"
        name="las la-exclamation-triangle q-mr-sm"
        color="warning"
        size="xs"
    >
        <q-tooltip>
            <span v-if="flags.LangsMisconfigured">
                {{ $t(`programs.info.misconfigured.langs${labelSuffix}`) }}
            </span>
            <br v-if="flags.LangsMisconfigured && flags.NoValidInstitution" />
            <span v-if="flags.NoValidInstitution">
                {{ $t(`programs.info.misconfigured.date${labelSuffix}`) }}
            </span>
        </q-tooltip>
    </q-icon>
</template>
