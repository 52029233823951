{
    "pages": {
        "main_title": "IES",
        "all": "Stránka nenalezena",
        "access-denied": "Přístup odepřen",
        "index": "Domovská stránka",
        "auth-login": "Přihlášení",
        "auth-forgot-password": "Zapomenuté heslo",
        "auth-set-password-token": "Nastavení hesla",
        "auth-change-password-token": "Změna hesla",
        "profile": "Profil",
        "admin": "Administrace",
        "admin-users": "Administrace - Uživatelé",
        "admin-users-new-user": "Administrace - Nový uživatel",
        "admin-users-id": "Administrace - Detail uživatele",
        "admin-email-templates": "Administrace - Šablony emailů",
        "admin-email-templates-id": "Administrace - Detail šablony emailu",
        "admin-files": "Administrace - Správa souborů",
        "admin-files-id": "Administrace - Správa souborů",
        "admin-employers": "Administrace - Správa zaměstnavatelů",
        "admin-employers-id": "Administrace - Správa zaměstnavatelů",
        "admin-countries": "Administrace - Správa zemí",
        "admin-languages": "Administrace - Správa jazyků",
        "admin-statistics": "Administrace - Statistiky",
        "admin-announcements": "Administrace - Oznámení",
        "admin-announcements-new": "Administrace - Nové oznámení",
        "admin-announcements-id": "Administrace - Detail oznámení",
        "institutions": "Instituce",
        "institutions-new": "Založení instituce",
        "institutions-id": "Detail instituce",
        "institutions-id-new-program": "Založení programu pro instituci",
        "programs": "Programy",
        "programs-new": "Založení programu",
        "programs-id": "Detail programu",
        "orders": "Objednávky",
        "orders-new": "Založení objednávky pro instituci",
        "orders-new-ici": "Založení objednávky pro studenta",
        "orders-id": "Detail objednávky",
        "certificates": "Certifikáty",
        "certificates-id": "Detail certifikátu",
        "certificates-students-id": "Detail studenta",
        "print-queue": "Tisková fronta"
    },
    "navigation": {
        "actions": {
            "backToOverview": "Zpět na přehled",
            "backToList": "Zpět na seznam"
        },
        "home": {
            "title": "Domovská stránka"
        },
        "institutions": {
            "title": "Instituce"
        },
        "programs": {
            "title": "Programy"
        },
        "orders": {
            "title": "Objednávky"
        },
        "certificates": {
            "title": "Certifikáty"
        },
        "queue": {
            "title": "Tisk"
        },
        "admin": {
            "title": "Administrace",
            "users": {
                "title": "Uživatelé",
                "subtitle": "Spravovat uživatele"
            },
            "emailTemplates": {
                "title": "Šablony emailů",
                "subtitle": "Spravovat šablony emailů"
            },
            "files": {
                "title": "Soubory",
                "subtitle": "Správa souborů"
            },
            "employers": {
                "title": "Zaměstnavatelé",
                "subtitle": "Spravovat číselník pro zaměstnavatele"
            },
            "languages": {
                "title": "Jazyky",
                "subtitle": "Spravovat číselník pro jazyky"
            },
            "countries": {
                "title": "Země",
                "subtitle": "Spravovat číselník pro země"
            },
            "statistics": {
                "title": "Statistiky",
                "subtitle": "Přehled statistik"
            },
            "announcements": {
                "title": "Oznámení",
                "subtitle": "Přehled oznámení"
            }
        }
    },
    "app": {
        "errors": {
            "title": "Chyba",
            "unavailable": "Aplikace je nedostupná, zkuste to prosím později.",
            "api": "API Chyba",
            "unauthorized": "Pro tuto akci nemáte oprávnění.",
            "session-expired": "Vaše relace vypršela, přihlaste se prosím znovu.",
            "403": {
                "title": "Přístup odepřen",
                "subtitle": "– Omlouváme se –",
                "text": "K této stránce nemáte přístup. Mužete však kliknout na tlačítko níže a vrátit se na domovskou stránku."
            },
            "404": {
                "title": "Stránka nenalezena",
                "subtitle": "– Omlouváme se –",
                "text": "Stránka, kterou hledáte, neexistuje. Jak jste se sem dostali, je záhadou. Můžete však kliknout na tlačítko níže a vrátit se na domovskou stránku."
            }
        },
        "internals": {
            "not-available": "API - Není k dispozici",
            "not-implemented": "Neimplemenováno ..."
        },
        "url": "IES-info.com",
        "short-name": "International Education Society Ltd.",
        "copyright": "Copyright ©2024"
    },
    "generic": {
        "boolean": {
            "true": "Ano",
            "false": "Ne"
        },
        "actions": {
            "remove": "Odebrat",
            "add": "Přidat",
            "reset": "Resetovat",
            "delete": "Odstranit",
            "activate": "Aktivovat",
            "deactivate": "Deaktivovat",
            "upload": "Nahrát",
            "confirm": "Potvrdit",
            "edit": "Upravit",
            "cancel": "Zrušit",
            "createRevision": "Vytvořit revizi",
            "print": "Vytisknout"
        },
        "tooltips": {
            "remove": "Odebrat",
            "edit": "Upravit",
            "delete": "Odstranit",
            "detail": "Detail",
            "copy": "Kopírovat",
            "clear": "Zrušit",
            "close": "Zavřít",
            "menu": "Menu",
            "create_revision": "Vytvořit revizi",
            "download": "Stáhnout"
        }
    },
    "enums": {
        "userType": {
            "Admin": "Admin",
            "Client": "Klient",
            "Approver": "Schvalovatel",
            "Student": "Student"
        },
        "institutionType": {
            "ElementarySchool": "Základní škola",
            "HighSchool": "Střední škola",
            "College": "Vysoká škola",
            "EducationAgency": "Vzdělávácí agentura",
            "PostSecondaryVocationalSchool": "Vyšší odborná škola"
        },
        "institutionState": {
            "InRegistration": "V registraci",
            "Active": "Aktivní",
            "Inactive": "Neaktivní"
        },
        "programState": {
            "Inactive": "Neaktivní",
            "WaitingForDocuments": "Dodání podkladů",
            "SentForCertification": "Odeslaný k certifikaci",
            "Certified": "Certifikovaný",
            "CertificationRefused": "Certifikace zamítnuta"
        },
        "orderPaymentType": {
            "Invoice": "Fakturou",
            "PaymentGateway": "Platební branou"
        },
        "orderState": {
            "Created": "Založeno",
            "AwaitingPayment": "Čeká na uhrazení",
            "Paid": "Zaplaceno",
            "Pending": "Nedokončeno",
            "Processing": "Generování certifikátů",
            "Completed": "Certifikáty vygenerovány"
        },
        "orderType": {
            "Institution": "Instituce",
            "CertOfSpecialist": "Profesní certifikát",
            "CertForLecturers": "Lektorský certifikát"
        },
        "certificateState": {
            "AwaitingPayment": "Čeká na uhrazení",
            "Processing": "Generování certifikátu",
            "Completed": "Certifikát vygenerován",
            "InQueue": "V tiskové frontě"
        },
        "certificateType": {
            "IES": "IES",
            "ICI": "ICI",
            "CertOfSpecialist": "Profesní certifikát",
            "CertForLecturers": "Lektorský certifikát"
        }
    },
    "components": {
        "uploader": {
            "dndText": "Přetáhněte soubory zde",
            "addFile": "Přidat soubor",
            "maxFileSize": "Max. velikost souboru",
            "validation": {
                "max-file-size": "Velikost souboru '%{file}' přesáhla povolenou maximální velikost.",
                "max-total-size": "Byl dosažen limit na celkovou velikost souborů.",
                "accept": "Typ souboru pro '%{file}' není podporovaný.",
                "duplicate": "Soubor '%{file}' už byl nahrán."
            }
        },
        "select": {
            "no-result": "Žádné data k dispozici"
        },
        "dialog": {
            "confirm": "OK",
            "cancel": "Zrušit"
        },
        "datePickerModal": {
            "reset": "reset"
        },
        "appForm": {
            "actions": {
                "create": "Vytvořit",
                "submit": "Uložit",
                "nextStep": "Pokračovat",
                "prevStep": "Zpět",
                "cancel": "Zahodit"
            }
        },
        "filterBar": {
            "hints": {
                "string": {
                    "Like": "obsahuje"
                },
                "boolean": {
                    "Eq": "je",
                    "Neq": "není"
                },
                "enum": {
                    "Eq": "je",
                    "Neq": "není"
                },
                "date": {
                    "Eq": "je",
                    "Neq": "není",
                    "Lte": "do",
                    "Gte": "od"
                },
                "number": {
                    "Eq": "rovná se",
                    "Neq": "nerovná se",
                    "Lte": "menší nebo rovno",
                    "Gte": "větší nebo rovno",
                    "Lt": "menší",
                    "Gt": "větší"
                }
            },
            "noData": "Žádné výsledky",
            "searchMenuLabel": "Vyhledat text",
            "searchFilterLabel": "Hledat"
        },
        "filterCard": {
            "actions": {
                "search": "Vyhledat",
                "clear": "Zrušit"
            },
            "noDataPlaceholder": "Nebyly nalezeny žádné výsledky",
            "searchPlaceholder": "Vyhledat",
            "additionalFilterLabel": "Filtrování"
        },
        "langTabs": {
            "modalTitle": "Nastavení jazyků",
            "saveLanguages": "Uložit jazyky"
        },
        "uploadFile": {
            "title": "Nahrát soubor",
            "inputs": {
                "DisplayName": "Název",
                "LanguageCode": "Jazyk",
                "File": "Soubor"
            }
        },
        "table": {
            "noDataLabel": "Žádné záznamy nejsou k dispozici"
        }
    },
    "auth": {
        "login": {
            "form": {
                "enterLoginMsg": "zadejte prosím Vaše přihlašovací údaje",
                "inputs": {
                    "Login": "Uživatelské jméno",
                    "Password": "Heslo"
                },
                "actions": {
                    "submit": "Přihlásit se"
                },
                "errors": {
                    "invalidCredentials": "Zadané přihlašovací údaje jsou neplatné"
                }
            },
            "forgotPassword": "Zaslat zapomenuté heslo?"
        },
        "logOut": {
            "title": "Odhlásit se"
        },
        "forgotPassword": {
            "form": {
                "enterEmailMsg": "Zadejte email k účtu, pro který chcete resetovat heslo. Na tento email vám přijde odkaz k resetu hesla.",
                "inputs": {
                    "Email": "Email"
                },
                "actions": {
                    "submit": "Zaslat odkaz"
                }
            },
            "goBack": "zpět na přihlášení"
        },
        "changePassword": {
            "form": {
                "inputs": {
                    "Password": "Heslo",
                    "PasswordConfirm": "Heslo znovu",
                    "OldPassword": "Aktuální heslo",
                    "NewPassword": "Nové heslo",
                    "NewPasswordConfirm": "Nové heslo znovu"
                },
                "actions": {
                    "submit": "Změnit heslo"
                }
            },
            "reset": {
                "changeMsg": "pro dokončení změny hesla zadejte nové heslo",
                "invalidTokenMsg": "odkaz pro změnu hesla už není validní",
                "submit": "Změnit heslo"
            },
            "set": {
                "changeMsg": "pro dokončení nastavení hesla zadejte heslo",
                "invalidTokenMsg": "odkaz pro nastavení nového hesla už není validní",
                "submit": "Nastavit heslo"
            },
            "change": {
                "title": "Změna hesla"
            },
            "goBack": "zpět na přihlášení"
        },
        "passwordRequirements": {
            "title": "Heslo musí mít:",
            "labels": {
                "MinLength": "Minimální délka: {0} znaků",
                "MixedCase": "Alespoň jedno velké a jedno malé písmeno",
                "Numbers": "Alespoň jedno číslo",
                "Symbols": "Alespoň jeden znak"
            }
        },
        "notify": {
            "forgotPasswordEmailSent": "Email s odkazem pro reset hesla byl úspěšně odeslán",
            "loggedIn": "Přihlášeno",
            "loggedOut": "Odhlášeno"
        }
    },
    "profile": {
        "labels": {
            "name": "Jméno",
            "type": "Typ",
            "email": "Email",
            "institution": "Instituce"
        },
        "actions": {
            "changePassword": "Změnit heslo"
        },
        "notify": {
            "changePasswordSuccess": "Heslo bylo úspěšně změněno"
        }
    },
    "dashboard": {
        "title": "Dashboard",
        "tableTitle": {
            "Admin": "Nové objednávky",
            "Approver": "Programy odeslané k certifikaci",
            "Client": "Objednávky čekající na zaplacení",
            "Student": "Moje certifikáty"
        },
        "actions": {
            "more": "Více"
        }
    },
    "admin": {
        "countries": {
            "table": {
                "addPlaceholder": "Přidejte novou zemi",
                "noDataPlaceholder": "Žádná nová země už není k dispozici",
                "columns": {
                    "Code": "Kód",
                    "Name": "Název (%{locale})"
                }
            },
            "dialog": {
                "remove": {
                    "title": "Odstranit zemi",
                    "message": "Opravdu chcete odstranit tuto zemi?"
                }
            },
            "form": {
                "title": "Upravit název země",
                "inputs": {
                    "Name": "Název (%{locale})"
                }
            }
        },
        "languages": {
            "table": {
                "addPlaceholder": "Přidejte nový jazyk",
                "noDataPlaceholder": "Žádný nový jazyk už není k dispozici",
                "columns": {
                    "Code": "Kód",
                    "Name": "Název (%{locale})"
                }
            },
            "dialog": {
                "remove": {
                    "title": "Odstranit jazyk",
                    "message": "Opravdu chcete odstranit tento jazyk?"
                }
            },
            "form": {
                "title": "Upravit název jazyka",
                "inputs": {
                    "Name": "Název (%{locale})"
                }
            }
        },
        "announcements": {
            "actions": {
                "new": "Nové oznámení",
                "edit": "Upravit",
                "backToList": "Zpět na seznam",
                "detail": "Detail",
                "delete": "Smazat"
            },
            "table": {
                "columns": {
                    "Subject": "Předmět",
                    "UserTypes": "Typy uživatele",
                    "VisibleFrom": "Viditelné od",
                    "VisibleTo": "Viditelné do",
                    "CreatedAt": "Vytvořeno",
                    "CreatedBy": "Vytvořil"
                }
            },
            "prompts": {
                "delete": {
                    "title": "Odstranit oznámení",
                    "message": "Opravdu chcete odstranit toto oznámení?",
                    "cancel": "Zrušit",
                    "confirm": "Odstranit"
                }
            },
            "form": {
                "submit": {
                    "create": "Vytvořit oznámení",
                    "update": "Uložit oznámení"
                },
                "inputs": {
                    "Subject": "Předmět",
                    "Body": "Obsah",
                    "VisibleFrom": "Viditelné od",
                    "VisibleTo": "Viditelné do",
                    "UserTypes": "Typy uživatele"
                }
            }
        },
        "users": {
            "active": "Aktivní",
            "inactive": "Neaktivní",
            "overview": {
                "columns": {
                    "FullName": "Jméno a přijmení",
                    "Login": "Uživatelské jméno",
                    "Email": "Email",
                    "Type": "Typ",
                    "IsActive": "Stav",
                    "Institution": "Instituce"
                },
                "actions": {
                    "new": "Nový uživatel"
                }
            },
            "detail": {
                "actions": {
                    "edit": "Editovat",
                    "resetPassword": "Resetovat heslo"
                }
            },
            "form": {
                "submit": {
                    "create": "Vytvořit",
                    "edit": "Upravit"
                },
                "inputs": {
                    "FirstName": "Jméno",
                    "LastName": "Přijmení",
                    "Login": "Uživatelské jméno",
                    "Email": "Email",
                    "Type": "Typ uživatele",
                    "Institution": "Instituce"
                }
            },
            "dialog": {
                "resetPassword": {
                    "title": "Resetovat heslo uživateli",
                    "message": "Opravdu chcete resetovat heslo tomuto uživateli?"
                }
            },
            "notify": {
                "resetPassword": "Heslo bylo zresetováno. Uživateli byl zaslán email s odkazem pro změnu hesla."
            }
        },
        "files": {
            "sharing": {
                "public": "Sdíleno",
                "private": "Soukromé",
                "title": "Sdílet soubor",
                "unpublish": "Zrušit sdílení",
                "urlCopied": {
                    "success": "Odkaz byl zkopírován do schránky",
                    "failure": "Váš prohlížeč nepodporuje kopírování do schánky."
                }
            },
            "actions": {
                "uploadFile": "Nahrát soubor",
                "newFolder": "Vytvořit složku",
                "rename": "Přejmenovat",
                "move": "Přesunout",
                "download": "Stáhnout",
                "share": "Sdílet",
                "delete": "Smazat"
            },
            "table": {
                "columns": {
                    "Name": "Název",
                    "Size": "Velikost",
                    "Sharing": "Sdílení",
                    "CreatedAt": "Vytvořeno",
                    "CreatedBy": "Vytvořil"
                }
            },
            "form": {
                "folder": {
                    "title": {
                        "new": "Nová složka",
                        "edit": "Přejmenovat složku"
                    },
                    "inputs": {
                        "Name": "Název"
                    },
                    "submit": {
                        "new": "Vytvořit",
                        "edit": "Přejmenovat"
                    }
                },
                "file": {
                    "title": {
                        "rename": "Přejmenovat soubor",
                        "move": "Přesunout soubor"
                    },
                    "submit": {
                        "rename": "Přejmenovat",
                        "move": "Přesunout"
                    },
                    "inputs": {
                        "DisplayName": "Název"
                    }
                }
            },
            "dialog": {
                "delete": {
                    "file": {
                        "title": "Odstranit soubor",
                        "message": "Opravdu chcete odstranit tento soubor?"
                    },
                    "folder": {
                        "title": "Odstranit složku",
                        "message": "Opravdu chcete odstranit tuto složku?"
                    }
                }
            }
        },
        "employers": {
            "institutions": {
                "title": "Výběr instituce",
                "columns": {
                    "Name": "Instituce"
                },
                "defaultName": "Vychozí"
            },
            "detail": {
                "title": "Seznam zaměstnavatelů",
                "actions": {
                    "new": "Nový zaměstnavatel"
                },
                "columns": {
                    "Name": "Jméno",
                    "Country": "Země",
                    "Industry": "Obor",
                    "Url": "Web"
                }
            },
            "form": {
                "title": {
                    "create": "Vytvořit zaměstnavatele",
                    "edit": "Upravit zaměstnavatele"
                },
                "inputs": {
                    "Name": "Jméno",
                    "CountryCode": "Země",
                    "Industry": "Obor",
                    "Url": "Web"
                },
                "submit": {
                    "create": "Vytvořit",
                    "edit": "Upravit"
                }
            },
            "dialog": {
                "delete": {
                    "title": "Odstranit zaměstnavatele",
                    "message": "Opravdu chcete odstranit tohoto zaměstnavatele?"
                }
            }
        },
        "emailTemplates": {
            "actions": {
                "detail": "Detail",
                "edit": "Upravit"
            },
            "overview": {
                "columns": {
                    "name": "Název"
                },
                "name": {
                    "questionnaireStateChangedMail": "Oznámení o stavu dotazníků",
                    "questionnaireCreatedMail": "Nový dotazník",
                    "programCertificationCreatedMail": "Nová certifikace oboru",
                    "questionnaireSubmittedMail": "Potvrzení o úspěšném odeslání vyplněného Dotazníku pro posouzení ratingu vzdělávací instituce",
                    "questionnaireEvaluatedMail": "Vyhodnocený dotazník",
                    "programToCertifyCreatedMail": "Nový obor k certifikaci",
                    "orderStateChangedMail": "Info o stavu objednávky"
                }
            },
            "form": {
                "submit": {
                    "update": "Uložit šablonu"
                },
                "inputs": {
                    "Subject": "Předmět",
                    "Body": "Obsah",
                    "Placeholders": "Placeholders"
                }
            },
            "placeholders": {
                "surveyUrl": "Url dotazníku",
                "programUrl": "Url programu",
                "loginUrl": "Url přihlášení",
                "login": "Přihlášení",
                "resetUrl": "Reset url",
                "OrderUrl": "Url objednávky",
                "OrderState": "Stav objednávky"
            }
        }
    },
    "institutions": {
        "form": {
            "revision": {
                "edit": "Upravit revizi",
                "create": "Vytvořit revizi"
            },
            "inputs": {
                "Name": "Název (řádek %{line})",
                "CountryCode": "Země instituce",
                "PrimaryLanguageCode": "Primární jazyk",
                "Rating": "Rating",
                "Street": "Ulice, č.p",
                "City": "Město",
                "Zip": "PSČ",
                "Type": "Typ instituce"
            }
        },
        "overview": {
            "columns": {
                "Name": "Název",
                "ActivatedAt": {
                    "eq": "Aktivováno",
                    "from": "Aktivováno od",
                    "to": "Aktivováno do"
                },
                "ActivatedBy": "Aktivoval",
                "Country": "Země",
                "Type": "Typ",
                "State": "Stav",
                "Languages": "Jazyky"
            },
            "actions": {
                "new": "Založit instituci"
            }
        },
        "detail": {
            "labels": {
                "Name": "Název",
                "Address": "Adresa",
                "ActivatedAt": "Aktivováno",
                "ActivatedBy": "Aktivoval",
                "UpdatedAt": "Upraveno",
                "UpdatedBy": "Upravil",
                "Country": "Země instituce",
                "Type": "Typ instituce",
                "PrimaryLanguage": "Úřední jazyk",
                "Rating": "Rating",
                "Street": "Ulice",
                "City": "Město",
                "Zip": "PSČ",
                "ValidFrom": "Platná od"
            },
            "actions": {
                "edit": "Upravit instituci",
                "activate": "Aktivovat",
                "deactivate": "Deaktivovat",
                "createRevision": "Vytvořit revizi"
            },
            "revisions": {
                "title": "Revize"
            },
            "programs": {
                "title": "Programy",
                "backToInstitutionDetail": "Zpět na instituci"
            }
        },
        "dialog": {
            "activate": {
                "title": "Aktivovat instituci",
                "message": "Opravdu chcete aktivovat tuto instituci?"
            },
            "deactivate": {
                "title": "Deaktivovat instituci",
                "message": "Opravdu chcete deaktivovat tuto instituci?"
            },
            "deleteRevision": {
                "title": "Odstranit revizi",
                "message": "Opravdu chcete smazat revizi '{0}' s platností od {1}?"
            }
        },
        "notify": {
            "activate": "Instituce byla úspěšně aktivována",
            "deactivate": "Instituce byla úspěšně deaktivována",
            "created": "Instituce byla úspěšně vytvořena",
            "createFailed": "Nepodařilo se vytvořit instituci",
            "deleteRevisionSuccess": "Revize instituce byla úspěšně smazána",
            "createRevisionSuccess": "Revize instituce byla úspěšně upravena",
            "editRevisionSuccess": "Institution revision edited successfully"
        }
    },
    "programs": {
        "overview": {
            "columns": {
                "ProgramName": "Název",
                "InstitutionName": "Instituce",
                "ValidFrom": "Platný od",
                "ValidTo": "Platný do",
                "Rating": "Rating",
                "CreatedAt": "Vytvořeno",
                "Duration": "Doba trvání",
                "Competences": "Kompetence",
                "CertifiedAt": {
                    "eq": "Certifikováno",
                    "from": "Certifikováno od",
                    "to": "Certifikováno do"
                },
                "State": "Stav"
            },
            "actions": {
                "new": "Založit program"
            }
        },
        "form": {
            "revision": {
                "edit": "Upravit revizi",
                "create": "Vytvořit revizi"
            },
            "info": {
                "inputs": {
                    "ProgramName": "Název (řádek %{line})",
                    "InstitutionName": "Instituce",
                    "Rating": "Rating",
                    "Competences": "Kompetence",
                    "Duration": "Doba trvání",
                    "ValidFrom": "Datum začátku platnosti",
                    "ValidTo": "Datum konce platnosti"
                },
                "submit": {
                    "create": "Vytvořit",
                    "edit": "Upravit",
                    "create_revision": "Vytvořit novou revizi"
                }
            },
            "changeState": {
                "inputs": {
                    "Rating": "Rating"
                },
                "submit": "Potvrdit"
            },
            "note": {
                "inputs": {
                    "Note": "Poznámka"
                },
                "submit": "Vložit"
            }
        },
        "detail": {
            "info": {
                "labels": {
                    "ProgramName": "Název",
                    "Institution": "Instituce",
                    "Rating": "Rating",
                    "Competences": "Kompetence",
                    "State": "Stav",
                    "CreatedAt": "Vytvořeno",
                    "CertifiedAt": "Certifikováno",
                    "ValidFrom": "Datum začátku platnosti",
                    "ValidTo": "Datum konce platnosti"
                }
            },
            "files": {
                "title": "Soubory",
                "columns": {
                    "DisplayName": "Název",
                    "LanguageCode": "Jazyk",
                    "Extension": "Typ",
                    "CreatedAt": "Datum vložení",
                    "User": "Vložil"
                },
                "noData": "Žádné soubory zatím nebyly nahrány"
            },
            "notes": {
                "title": "Poznámky",
                "noData": "Žádné poznámky zatím nebyly přidány",
                "addNote": "Vložit poznámku"
            },
            "actions": {
                "edit": "Upravit program",
                "changeState": "Změnit stav",
                "uploadFile": "Nahrát soubor",
                "create_revision": "Vytvořit novou revizi"
            },
            "revisions": {
                "title": "Revize programu"
            }
        },
        "dialog": {
            "deleteFile": {
                "title": "Odstranit soubor",
                "message": "Opravdu chcete odstranit tento soubor z programu?"
            },
            "deleteNote": {
                "title": "Odstranit poznámku",
                "message": "Opravdu chcete odstranit tuto poznámku z programu?"
            },
            "changeState": {
                "title": "Potvrdit změnu",
                "message": "Opravdu chcete změnit stav u programu?"
            },
            "deleteRevision": {
                "title": "Odstranit revizi",
                "message": "Opravdu chcete smazat revizi '{0}' s platností od {1}?"
            }
        },
        "changeState": {
            "Inactive": "Deaktivovat",
            "WaitingForDocuments": "Dodat podklady",
            "SentForCertification": "Odeslat k certifikaci",
            "Certified": "Potvrdit certifikaci",
            "CertificationRefused": "Zamítnout certifikaci"
        },
        "notify": {
            "changeProgramStateSuccess": "Stav programu byl uspěšně změněn",
            "deleteRevisionSuccess": "Revize programu byla úspěšně smazána",
            "created": "Program byl úspěšně vytvořen",
            "createRevisionSuccess": "Revize programu byla úspěšně vytvořena",
            "editRevisionSuccess": "Revize programu byla úspěšně upravena"
        },
        "info": {
            "misconfigured": {
                "langs": "Některé revize programu nemají zadány všechny jazyky.",
                "langs_single": "Revize programu nemaá zadány všechny jazyky.",
                "date": "Některé revize programu mají plantost, pro kterou neexistuje adekvátní revize instituce.",
                "date_single": "Revize programu má plantost, pro kterou neexistuje adekvátní revize instituce."
            }
        }
    },
    "orders": {
        "changeState": {
            "AwaitingPayment": "Čeká na uhrazení",
            "Paid": "Potvrdit zaplacení",
            "Pending": "Stornovat",
            "Processing": "Vygenerovat certifikáty"
        },
        "overview": {
            "actions": {
                "new": {
                    "title": "Nová objednávka",
                    "options": {
                        "Institution": "pro instituci",
                        "Student": "pro studenta"
                    }
                }
            },
            "columns": {
                "Id": "ID",
                "ProgramName": "Program",
                "InstitutionName": "Instituce",
                "State": "Stav",
                "FinishedAt": {
                    "eq": "Datum ukončení",
                    "from": "Datum ukončení od",
                    "to": "Datum ukončení do"
                }
            }
        },
        "form": {
            "new": {
                "student": "Student",
                "students": "Studenti",
                "certificates": "Výběr certifikátů",
                "summary": "Shrnutí objednávky",
                "steps": {
                    "Order": "Objednávka",
                    "Students": "Studenti",
                    "Certificates": "Certifikáty",
                    "Summary": "Shrnutí"
                },
                "inputs": {
                    "Type": "Typ",
                    "Currency": "Měna",
                    "Price": "Cena",
                    "Institution": "Instituce",
                    "Program": "Program",
                    "ProgramName": "Název programu",
                    "FinishedAt": "Datum ukončení",
                    "PublishAt": "Datum zpřístupnění účtu",
                    "RecipientEmail": "Email příjemce",
                    "PaymentType": "Způsob platby",
                    "Competences": "Kompetence",
                    "FieldOfActivity": "Kompetence",
                    "Student": {
                        "DegreeBefore": "Titul před",
                        "FirstName": "Jméno",
                        "LastName": "Přijmení",
                        "DegreeAfter": "Titul za",
                        "Birthday": "Datum narození",
                        "Email": "Email"
                    }
                },
                "labels": {
                    "orderType": "Vyberte typ objednávky",
                    "orderTypeTitle": "Typ objednávky"
                },
                "actions": {
                    "back": "Zpět",
                    "next": "Pokračovat",
                    "submit": "Objednat"
                }
            },
            "publishAt": {
                "title": "Úprava objednávky",
                "inputs": {
                    "PublishAt": "Datum zpřístupnění účtu"
                }
            },
            "revision": {
                "edit": "Upravit revizi",
                "name": "Název"
            },
            "changeState": {
                "title": "Zadat uhrazení platby",
                "inputs": {
                    "Price": "Cena",
                    "Currency": "Měna",
                    "Files": "Faktury"
                },
                "submit": "Nastavit"
            },
            "columns": {
                "DegreeBefore": "Titul před",
                "FirstName": "Jméno",
                "LastName": "Přijmení",
                "DegreeAfter": "Titul za",
                "Birthday": "Datum narození",
                "Email": "Email",
                "Languages": "Jazyky",
                "LanguagesCount": "Celkem"
            }
        },
        "detail": {
            "order": "Objednávka #%{id}",
            "students": "Studenti",
            "files": {
                "title": "Faktury",
                "noData": "Žádné faktury zatím nebyly nahrány",
                "columns": {
                    "DisplayName": "Název",
                    "Extension": "Typ",
                    "CreatedAt": "Datum vložení",
                    "User": "Vložil"
                }
            },
            "labels": {
                "Program": "Program",
                "Institution": "Instituce",
                "State": "Stav",
                "FinishedAt": "Datum ukončení",
                "PublishAt": "Datum zpřístupnění účtu",
                "RecipientEmail": "Email příjemce",
                "PaymentType": "Způsob platby",
                "Competences": "Kompetence",
                "FieldOfActivity": "Kompetence",
                "Price": "Částka"
            },
            "columns": {
                "DegreeBefore": "Titul před",
                "FirstName": "Jméno",
                "LastName": "Přijmení",
                "DegreeAfter": "Titul za",
                "Birthday": "Datum narození",
                "Email": "Email",
                "Languages": "Jazyky",
                "LanguagesCount": "Celkem"
            },
            "actions": {
                "changeState": "Změnit stav",
                "changePublishAt": "Upravit",
                "addToQueue": "Přidat do fronty"
            }
        },
        "dialog": {
            "changeState": {
                "title": "Potvrdit změnu",
                "message": "Opravdu chcete změnit stav u objednávky?"
            },
            "deleteFile": {
                "title": "Odstranit fakturu",
                "message": "Opravdu chcete odstranit tuto fakturu z objednávky?"
            }
        },
        "notify": {
            "sentToQueue": "Certifikáty byly úspěšně odeslány do fronty.",
            "created": "Objednávky byla úspěšně vytvořena"
        }
    },
    "certificates": {
        "overview": {
            "columns": {
                "Id": "ID",
                "Name": "Jméno",
                "ProgramName": "Program",
                "InstitutionName": "Instituce",
                "State": "Stav",
                "Language": "Jazyk",
                "Type": "Typ",
                "Number": "Číslo certifikátu",
                "CreatedAt": {
                    "eq": "Vytvořeno",
                    "from": "Vytvořeno od",
                    "to": "Vytvořeno do"
                },
                "FinishedAt": {
                    "eq": "Ukončeno",
                    "from": "Ukončeno od",
                    "to": "Ukončeno do"
                }
            }
        },
        "student": {
            "title": "Informace o studentovi",
            "certificates": "Certifikáty",
            "labels": {
                "Name": "Jméno",
                "Program": "Program",
                "Institution": "Instituce",
                "PublishAt": "Datum zpřístupnění účtu",
                "FinishedAt": "Datum ukončení",
                "Birthday": "Datum narození",
                "Email": "Email"
            },
            "columns": {
                "Id": "ID",
                "Name": "Jméno",
                "Language": "Jazyk",
                "State": "Stav",
                "Number": "Číslo certifikátu",
                "Type": "Typ"
            },
            "actions": {
                "addAllToQueue": "Přidat do fronty",
                "addToQueue": "Přidat do fronty"
            }
        },
        "queue": {
            "noData": "Tisková fronta je prázdná",
            "columns": {
                "Id": "ID",
                "Name": "Jméno",
                "Language": "Jazyk",
                "InstitutionName": "Instituce",
                "ProgramName": "Program",
                "CreatedAt": "Vloženo",
                "FinishedAt": "Datum ukončení",
                "Number": "Číslo certifikátu",
                "Type": "Typ"
            },
            "actions": {
                "print": "Ostrý tisk"
            },
            "tooltips": {
                "removeFromQueue": "Odebrat z fronty",
                "view": "Náhled"
            }
        },
        "detail": {
            "orderTitle": "Objednávka",
            "certificateTitle": "Certifikát",
            "labels": {
                "number": "Číslo certifikátu",
                "type": "Typ",
                "language": "Jazyk",
                "institution": "Instituce",
                "institutionRating": "Rating instituce",
                "program": "Program",
                "programRating": "Rating programu",
                "name": "Jméno",
                "student": "Student",
                "email": "Email",
                "birthday": "Datum narození",
                "state": "Stav"
            },
            "actions": {
                "orderDetail": "Detail objednávky",
                "downloadCertificate": "Stáhnout certifikát"
            }
        },
        "tooltips": {
            "studentDetail": "Detail studenta",
            "certificateDetail": "Detail certifikátu",
            "addToQueue": "Přidat do fronty"
        },
        "dialog": {
            "addToQueueStudent": {
                "title": "Přidat do tiskové fronty",
                "message": "Opravdu chcete přidat všechny certifikáty tohoto studenta do tiskové fronty?"
            },
            "addToQueueCert": {
                "title": "Přidat do tiskové fronty",
                "message": "Opravdu chcete přidat tento certifikát do tiskové fronty?"
            },
            "addToQueueOrder": {
                "title": "Přidat do tiskové fronty",
                "message": "Opravdu chcete přidat tuto objednávku do tiskové fronty?"
            },
            "removeFromQueueCert": {
                "title": "Odebrat z tiskové fronty",
                "message": "Opravdu chcete odebrat tento certifikát z tiskové fronty?"
            },
            "printQueue": {
                "title": "Vytisknout frontu",
                "message": "Opravdu chcete vytisknout frontu pro jazyk '{lang}'?"
            }
        }
    }
}
